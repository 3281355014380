import { Box, Button, Grid, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDateString, getRoundName } from "../common/RoundHelper";
import pridePick13Logo from '../images/opeimujackpot13.png';
import pridePick12Logo from '../images/opeimujackpot12.png';
import pridePick8Logo from '../images/opeimujackpot8.png';
import leoPick13 from '../images/LeoPick13.png';
import leoPick12 from '../images/LeoPick12.png';
import leoPick8 from '../images/LeoPick8.png';
import { PoolXNumberFormat } from "../common/CurrencyFormatter";
import { Syndicate } from "../models/syndicate";
import { Link } from 'react-router-dom'
import { gradientsC, productNamesC, themeC } from "../theme/themehelper";
import { EightIcon, ThirteenIcon, TwelveIcon } from "../reusable-components";
import { ShallowRound } from "../rounds/types";
import kung8 from '../images/kung8.png';
import kung12 from '../images/kung12.png';
import kung13 from '../images/kung13.png';
import { DbetArrowRight, DbetEightIcon, DbetEightLadder, DbetThirteenIcon, DbetThirteenLadder, DbetTwelveIcon, DbetTwelveLadder } from "../images/dbeticons";

function SyndicatePlate({ syndicate, round, theme }: { syndicate: Syndicate, round: ShallowRound, theme: string }) {

    const { t } = useTranslation();
    const dateString = getDateString(round, t);
    const { totalAmountPerShare } = syndicate || {};
    const { amount, currency } = totalAmountPerShare || {};

    let locale = t('locale');
    const nf = PoolXNumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let formattedStakePerShare = nf.format(amount);

    if (syndicate === undefined || syndicate.coupon === undefined) {
        return (<></>);
    }

    let borderWidth = "1px";

    let smallText: SxProps<Theme> = {
        fontSize: "14px",
        color: 'var(--text-black)',
        lineHeight: "21px",
        fontWeight: "400",
        fontFamily: 'var(--font-body)',
        marginTop: '4px',
        marginBottom: '7px',
    };

    let smallTextValues: SxProps<Theme> = {
        fontSize: "14px",
        color: 'var(--text-black)',
        lineHeight: "21px",
        fontWeight: "400",
        fontFamily: 'var(--font-body)',
        marginTop: '4px',
        marginBottom: '7px',
    };

    let smallTitleUpper: SxProps<Theme> = {
        fontSize: "24px",
        color: 'var(--text-black)',
        lineHeight: "30px",
        fontWeight: "700",
        fontFamily: 'var(--font-title)',
        textTransform: 'uppercase',
        marginTop: '-10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        maxWidth: '200px',
        textAlign: "center",
        minHeight: '60px'
    };

    let button: SxProps<Theme> = {
        marginTop: '10px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '25px',
        paddingRight: '25px',
        gap: '4px',
        color: 'var(--button-text-color)',
        height: '32px',
        textTransform: 'var(--text-transform-button)',
        fontWeight: 'var(--font-weight-button)',
        fontSize: '12px',
        lineHeight: '18px',
        border: borderWidth + ' solid #DADADA',
        borderRadius: '5px',
        backgroundColor: "var(--button-color)",
        "&:hover": {
            backgroundColor: "var(--button-hover-color)",
            border: borderWidth + ' solid #DADADA',
        }
    }

    let logo = pridePick8Logo;
    let gradient = 'linear-gradient(335deg,#A8301F,#FA9547,#D09C72,#B74D01)';

    let roundName = getRoundName(round, t);
    let background = 'var(--background_4)';
    let border = "";
    let boxShadow = "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px";

    switch (roundName) {
        case "Pride Pick 12":
            logo = pridePick12Logo;
            gradient = 'linear-gradient(335deg,#A8A8A8,#DEDEDE,#A3ADC0,#A8A8A8)';
            break;
        case "Pride Pick 13":
            logo = pridePick13Logo;
            gradient = 'linear-gradient(335deg,#FBBA2E,#FFEDC0,#DD9F00)';
            break;
        default:
            break;
    }

    if (theme === themeC.Tomato) {
        background = '#FFF';
        gradient = 'linear-gradient(0deg,#17CB6F,#1DAB63)';
        logo = leoPick8;
        if (roundName.indexOf("13") > 0) {
            logo = leoPick13;
            gradient = 'linear-gradient(0deg,#00A6FF,#0085FF)';
        }

        if (roundName.indexOf("12") > 0) {
            logo = leoPick12;
            gradient = 'linear-gradient(0deg,#EE44EE,#CC00FF)';
        }
    }

    let mixMaxFlex = "";
    let number = "";
    let strokeColor = "";
    let logoHeight = "44px";
    let logoHeight2 = "35px";
    let logoWidth = "100px";
    let borderImageSource = "none";
    let minMaxFlexTextColor = 'var(--text-white)';
    let numberBackgroundHeight = "64px";
    let numberBackgroundWidth = "77px";
    let numberImage = "";
    let fontFamily = 'var(--font-title)';
    let numberIcon = <></>;

    if ((theme === themeC.Medina || window.theme === themeC.MedinaAr)) {
        mixMaxFlex = "Flex";
        number = "8x";
        background = 'var(--background_4)';
        gradient = "linear-gradient(0deg, #212654, #212654),linear-gradient(180deg, #F37B35 0%, rgba(243, 123, 53, 0) 100%)";
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";
        borderImageSource = "linear-gradient(180deg, #F37B35 0%, rgba(243, 123, 53, 0) 100%)";

        strokeColor = "linear-gradient(102.58deg, #F37834 1.33%, #F6C55E 277.24%)";
        if (roundName.indexOf("Max") > -1) {
            mixMaxFlex = "Max";
            number = "13x";
            logo = "none";
            gradient = "linear-gradient(0deg, #394078, #394078),linear-gradient(180deg, #F3BA27 0%, rgba(0, 0, 0, 0) 100%)";
            strokeColor = "linear-gradient(98.24deg, #F3BA27 2.15%, #F6C55E 101.62%)";
            borderImageSource = "linear-gradient(180deg, #F3BA27 0%, rgba(0, 0, 0, 0) 100%)";
        }

        if (roundName.indexOf("Mix") > -1) {
            mixMaxFlex = "Mix";
            number = "12x";
            logo = "none";
            gradient = "linear-gradient(0deg, #282E61, #282E61),linear-gradient(180deg, #93B9DE 0%, rgba(0, 0, 0, 0) 100%)";
            strokeColor = "linear-gradient(255.21deg, #C7CCF5 13.84%, #93B9DE 112.93%)";
            borderImageSource = "linear-gradient(180deg, #93B9DE 0%, rgba(0, 0, 0, 0) 100%)";
        }
    }

    if (theme === themeC.Kung) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#78C4F8",
            fontWeight: "400",
            fontFamily: 'var(--font-title)',
            margin: '8px'
        };

        smallTextValues = {
            fontSize: "14px",
            color: '#FFF',
            lineHeight: "21px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '4px',
            marginBottom: '7px',
        };

        mixMaxFlex = productNamesC.Kung.Flex;
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Kung.Flex;
        logo = "none";
        logoHeight = "110px";
        logoHeight2 = "110px";
        logoWidth = "100%";
        numberImage = kung8;
        minMaxFlexTextColor = "#27FFAA";
        strokeColor = "none";
        numberBackgroundHeight = "61px";
        numberBackgroundWidth = "46px";

        button.border = "none";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Kung.Max;
            number = "13";
            gradient = gradientsC.Kung.Max;
            numberImage = kung13;
            minMaxFlexTextColor = "#34C2FF";
            numberBackgroundHeight = "64px";
            numberBackgroundWidth = "77px";
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Kung.Mix;
            number = "12";
            gradient = gradientsC.Kung.Mix;
            numberImage = kung12;
            minMaxFlexTextColor = "#EE8DFF";
            numberBackgroundHeight = "62px";
            numberBackgroundWidth = "77px";
        }

        numberImage = `url(${numberImage})`;
        border = "";
    }

    if (theme === themeC.Dbet) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#5E5E5E",
            fontWeight: "400",
            fontFamily: 'var(--font-title)',
            margin: '8px'
        };

        smallTextValues = {
            fontSize: "14px",
            color: '#232323',
            lineHeight: "21px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '4px',
            marginBottom: '7px',
        };

        mixMaxFlex = productNamesC.Kung.Flex;
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Dbet.Flex;
        logo = "none";
        logoHeight = "110px";
        logoHeight2 = "110px";
        logoWidth = "100%";
        numberImage = "";
        minMaxFlexTextColor = "#F4C004";
        strokeColor = "none";
        numberBackgroundHeight = "61px";
        numberBackgroundWidth = "95px";
        fontFamily = "Righteous";
        numberIcon =
            <Grid container direction="row" alignItems="center" >
                <Grid sx={{ marginRight: "9px", paddingLeft: "23px" }}>
                    {DbetEightIcon(61, 46)}
                </Grid>
                {DbetEightLadder(25, 15)}
            </Grid>

        button.border = "none";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Kung.Max;
            number = "13";
            gradient = gradientsC.Dbet.Max;
            numberBackgroundHeight = "64px";
            numberBackgroundWidth = "125px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "9px", paddingLeft: "23px" }}>
                        {DbetThirteenIcon(62, 77)}
                    </Grid>
                    {DbetThirteenLadder(25, 15)}
                </Grid>
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Kung.Mix;
            number = "12";
            gradient = gradientsC.Dbet.Mix;
            numberBackgroundHeight = "62px";
            numberBackgroundWidth = "125px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "9px", paddingLeft: "23px" }}>
                        {DbetTwelveIcon(62, 77)}
                    </Grid>
                    {DbetTwelveLadder(25, 15)}
                </Grid>
        }

        numberImage = `url(${numberImage})`;
        border = "";
    }

    if (theme === themeC.Blue2) {
        mixMaxFlex = "Mini";
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Blue2.Flex;
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = "Major";
            number = "13";
            logo = "none";
            gradient = gradientsC.Blue2.Max;
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = "Minor";
            number = "12";
            logo = "none";
            gradient = gradientsC.Blue2.Mix;
        }

        border = "";
    }

    if (theme === themeC.Orange) {
        mixMaxFlex = "Mini";
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Blue2.Flex;
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = "Major";
            number = "13";
            logo = "none";
            gradient = gradientsC.Blue2.Max;
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = "Minor";
            number = "12";
            logo = "none";
            gradient = gradientsC.Blue2.Mix;
        }

        border = "";
    }

    let icon: JSX.Element = <></>;

    if (theme === themeC.Light) {
        background = 'var(--background_4)';
        gradient = "linear-gradient( 0deg, #E0D8EE 0%, #E0D8EE 100%)";
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";
        icon = <EightIcon height="40" />;

        if (roundName.indexOf("Max") > -1) {
            mixMaxFlex = "Max";
            number = "13";
            logo = "none";
            gradient = "linear-gradient( 0deg, #B8CCF1 0%, #B8CCF1 100%)";
            icon = <ThirteenIcon height="40" />;
        }

        if (roundName.indexOf("Mix") > -1) {
            mixMaxFlex = "Mix";
            number = "12";
            logo = "none";
            gradient = "linear-gradient( 0deg, #FAEEAE 0%, #FAEEAE 100%)";
            icon = <TwelveIcon height="40" />;
        }

        border = "";
    }

    const reportSelection = () => {

        window.gtag("event", "select_content", {
            content_type: "syndicate",
            content_id: "syndicate"
        });
    }

    return (

        <Grid sx={{
            minWidth: "250px",
            border: border,
            borderRadius: 'var(--radius)',
            backgroundImage: borderImageSource,
            backgroundOrigin: "border-box",
            backgroundClip: "padding-box, border-box",
            padding: "0px",
            margin: "10px"
        }}>
            <Grid container direction='column' alignItems='center'
                sx={{
                    background: background,
                    margin: '0px',
                    borderRadius: 'var(--radius)',
                    boxShadow: boxShadow
                }}>

                <Grid container justifyContent='center' alignItems='center' sx={{
                    backgroundImage: gradient,
                    width: '100%',
                    height: logoHeight,
                    borderTopLeftRadius: 'var(--radius)',
                    borderTopRightRadius: 'var(--radius)'
                }}>
                    <Grid item sx={{
                        width: logoWidth,
                        height: logoHeight2,
                        backgroundImage: `url(${logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        {(theme === themeC.Medina || window.theme === themeC.MedinaAr) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "10px" }}>
                                <Typography sx={{
                                    fontSize: "12px",
                                    fontFamily: "monumentextended",
                                    color: 'var(--text-black)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "40px",
                                    fontFamily: "monumentextended",
                                    background: strokeColor,
                                    backgroundClip: "text",
                                    color: "transparent",
                                    display: "inline-block",
                                    fontWeight: "800",
                                    marginTop: "-10px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }
                        {theme === themeC.Light &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "15px" }}>
                                {icon}
                            </Grid>
                        }
                        {(theme === themeC.Blue2 || theme === themeC.Sultan || theme === themeC.Orange) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                                <Typography sx={{
                                    fontSize: "12px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "40px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "800",
                                    marginTop: "-10px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }
                        {(window.theme === themeC.Kung || window.theme === themeC.Dbet) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "-5px", marginBottom: "11px" }}>
                                <Typography sx={{
                                    fontSize: "20px",
                                    fontFamily: fontFamily,
                                    color: minMaxFlexTextColor,
                                    fontWeight: theme === themeC.Kung ? "700" : "400",
                                    textTransform: "uppercase",
                                    marginTop: "10px",
                                    marginBottom: "2px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundImage: numberImage,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        height: numberBackgroundHeight,
                                        width: numberBackgroundWidth,
                                    }}
                                >
                                    {numberIcon}
                                </Box>
                            </Grid>
                        }
                    </Grid>

                </Grid>

                <Grid container direction='column' alignItems='center'>
                    <Typography sx={smallText}>{dateString}</Typography>
                    <Typography sx={smallTitleUpper} >{syndicate.name}</Typography>
                    <Grid>
                        <Grid container alignItems='baseline'>
                            <Typography sx={smallText}>{t('number_of_rows') + ': '}</Typography>
                            <Typography sx={smallTextValues}>{syndicate.coupon.totalRows}</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container alignItems='baseline'>
                            <Typography sx={smallText}>{t('shares') + ': '}</Typography>
                            <Typography sx={smallTextValues}>{'(' + syndicate.nShares + '/' + syndicate.targetNShares + ')'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container alignItems='baseline'>
                            <Typography sx={smallText}>{t('share_price') + ': '}</Typography>
                            <Typography sx={smallTextValues}>{formattedStakePerShare}</Typography>
                        </Grid>
                    </Grid>

                    <Link to={`/syndicate/${syndicate.id}`}>

                        <div onClick={() => reportSelection()}>
                            {theme !== themeC.Dbet &&
                                <Button sx={button}>{t('to_syndicate')}</Button>
                            }
                            {theme === themeC.Dbet &&
                                <Button sx={button}>
                                    <Typography sx={{ fontSize: "14px", marginRight: "10px", fontWeight: "700" }}>{t('to_syndicate')}</Typography>
                                    <Grid sx={{ marginTop: "5px" }}>{DbetArrowRight}</Grid>
                                </Button>
                            }
                        </div>

                    </Link>
                    <Typography sx={smallText}></Typography>
                </Grid>
            </Grid >
        </Grid >

    );

}

export default SyndicatePlate;