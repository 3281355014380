import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { gradientsC, themeC } from "../theme/themehelper";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import image from '../images/footballfield.jpg';
import prideImage from '../images/PridePick13.png';
import kungImage from '../images/kung_syndicates.png';
import kungGroup from '../images/kung_people.png';
import kungValid from '../images/kung_valid.png';
import kungWin from '../images/win_kung.png';
import dbetImage from '../images/dbet_syndicate.jpeg';

function SyndicateInfoBox({ theme }: { theme: string }) {
    const { t } = useTranslation();
    
    let backgroundImage = `url(${image})`;

    let borderColor = "";

    if (theme === themeC.Blue2) {
        backgroundImage = gradientsC.Blue2.Landing;
    }

    if (theme === themeC.Gold) {
        backgroundImage = `url(${prideImage})`;
    }

    if (theme === themeC.Kung) {
        backgroundImage = `url(${kungImage})`;
        borderColor = "#249CEC";
    }

    if (theme === themeC.Dbet) {
        backgroundImage = `url(${dbetImage})`;
        borderColor = "#F4C004";
    }

    let boxInPicture: SxProps<Theme> = {
        position: "relative",
        width:"100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--text-white)",
        padding: "10px",
        "::before": {
            content: '""',
            opacity: "0.7",
            background: "var(--title-bar-background)",
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            borderRadius: 'var(--radius)'
        }
    };

    let textColor: SxProps<Theme> = {
        color: "var(--text-white)"
    }

    let betFollowWin: SxProps<Theme> = {
        fontFamily: "var(--font-title)",
        fontSize: "var(--font-title-size)",
        letterSpacing: "var(--font-title-letter-spacing)",
        lineHeight: "30px",
        fontWeight: "var(--font-title-weight)",
        margin: 0,
        textTransform: "uppercase"
    }

    if (window.theme === themeC.Dbet) {
        betFollowWin.fontFamily = "Righteous";
        betFollowWin.fontSize = "14px";
        betFollowWin.fontWeight = 400;
        betFollowWin.lineHeight = "17.38px";
      }

    let groupIcon = `url(${kungGroup})`;
    let validIcon = `url(${kungValid})`;
    let winIcon = `url(${kungWin})`;

    return (

        <Grid container sx={{
            width: "100%",
            height: "375px",
            background: backgroundImage,
            justifyContent: "center",
            alignItems: "center",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "var(--text-white)"
        }}>

            <Grid container justifyContent="center" >
                <Grid item sm={5} height="100%">
                    <Grid container direction="column" alignItems="center" sx={boxInPicture}>

                        <List className='list-item'>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    {(theme === themeC.Kung || theme === themeC.Dbet) ? <Box sx={{ backgroundImage: groupIcon, width: "47px", height: "28px" }} /> :
                                        <GroupsIcon sx={textColor} />}

                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={betFollowWin}
                                        >
                                            {t('join_title')}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                        >
                                            {t('join_text')}
                                        </Typography>
                                    } />
                            </ListItem>
                            {(theme === themeC.Kung || theme === themeC.Dbet) &&
                                <ListItem>
                                    <Grid container direction="row" justifyContent="center" sx={{ marginTop: "10px" }}>
                                        <Box sx={{ border: "2px solid " + borderColor, width: "18px", height: "0px" }} />
                                    </Grid>
                                </ListItem>
                            }
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    {(theme === themeC.Kung || theme === themeC.Dbet) ? <Box sx={{ backgroundImage: validIcon, width: "40px", height: "40px" }} /> :
                                        <TaskAltIcon sx={textColor} />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography

                                            sx={betFollowWin}
                                        >
                                            {t('valid_title')}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"

                                        >
                                            {t('valid_text')}
                                        </Typography>
                                    }
                                />
                            </ListItem>

                            {(theme === themeC.Kung || theme === themeC.Dbet) &&
                                <ListItem>
                                    <Grid container direction="row" justifyContent="center" sx={{ marginTop: "10px" }}>
                                        <Box sx={{ border: "2px solid "  + borderColor, width: "18px", height: "0px" }} />
                                    </Grid>
                                </ListItem>
                            }

                            <ListItem disablePadding>
                                <ListItemIcon>
                                    {(theme === themeC.Kung || theme === themeC.Dbet) ? <Box sx={{ backgroundImage: winIcon, width: "40px", height: "40px" }} /> :
                                        <EmojiEventsIcon sx={textColor} />}

                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography

                                            sx={betFollowWin}
                                        >
                                            {t('win_together_title')}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"

                                        >
                                            {t('win_together_text')}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );

}
export default SyndicateInfoBox;