import {Grid, SxProps, Theme, ToggleButton, Typography, useMediaQuery} from "@mui/material";
import {fixtureTime} from "../common/DateTimeHelper";
import {Button, CashIcon, CircleIcon, HalfCircleIcon, ShadowDiv} from "../reusable-components";
import {FormEvent, useEffect, useState} from 'react';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import {formatFraction} from "../common/RoundHelper";
import SlimFixtureDetail from "./slimfixturedetail";
import {Fixture} from "../models/fixture";
import {DetailedRound} from "../models/round";
import {Syndicate} from "../models/syndicate";
import {Bet, Coupon, CouponFixtureSummary, Leg, System} from "../models/coupon";
import {couponTotal, getDrawText, getWholeAndHalfPutsFromArray} from "./couponhelpers";
import {IsRightToLeftTheme, themeC} from "../theme/themehelper";
import StakeMultiplier from "./stakemultiplier";
import ProPick from "../routedround/components/couponform/components/propick";
import {PoolXMoney} from "../models/money";
import ConfirmPayment from "../routedround/components/couponform/components/confirmpayment";
import {operatorAdviceScrollToTop} from "../App";
import {usePlayer} from "../player/playerContext";
import {RoundInformation} from "../routedround/components/roundinformation";
import CloseIcon from '@mui/icons-material/Close';
import ReducedSystemSelector from "../routedround/components/couponform/components/ReducedSystemSelector";
import {useTranslation} from "react-i18next";
import {PoolXNumberFormat} from "../common/CurrencyFormatter";

function SlimCoupon({
                        round,
                        syndicate,
                        onSubmit,
                        theme,
                        showProPick,
                        setShowProPick,
                        showConfirm,
                        setShowConfirm,
                        submitFailed,
                        submitError,
                        submitting,
                        stakeMultiplier,
                        setStakeMultiplier,
                        onChangeFixture,
                        showRoundInfo,
                        setShowRoundInfo,
                        showRSystem,
                        setShowRSystem,
                        proPickRows,
                        setProPickRows,
                        coupon,
                        couponFixtureSummaries
                    }:
                        {
                            round: DetailedRound,
                            syndicate: Syndicate | undefined,
                            onSubmit: (coupon: Coupon) => void,
                            theme: string,
                            showProPick: boolean,
                            setShowProPick: (show: boolean) => void,
                            showConfirm: boolean,
                            setShowConfirm: (show: boolean) => void,
                            submitFailed: boolean,
                            submitError: string | undefined,
                            submitting: boolean,
                            stakeMultiplier: number,
                            setStakeMultiplier: (multiplier: number) => void,
                            onChangeFixture: (index: number) => void,
                            showRoundInfo: boolean,
                            setShowRoundInfo: (show: boolean) => void,
                            showRSystem: boolean,
                            setShowRSystem: (show: boolean) => void,
                            proPickRows: string[][] | undefined,
                            setProPickRows: (rows: string[][] | undefined) => void,
                            coupon: Coupon,
                            couponFixtureSummaries: CouponFixtureSummary[]
                        }) {

    let emptyForm: Coupon | undefined;
    let emptyExpandedFixtures: number[] = [];
    let [formData, setFormData] = useState(emptyForm);
    let [expandedFixtures, setExpandedFixtures] = useState(emptyExpandedFixtures);
    let [counter, setCounter] = useState(0);
    let [valid, setValid] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    let playerResp: any = usePlayer();
    const {t} = useTranslation();

    useEffect(() => {

        if (formData !== undefined && round !== undefined && proPickRows !== undefined && proPickRows.length === round.fixtures.length) {
            formData.bets[0].legs = round.fixtures.map((_fixture, index) => {
                let myLeg: Leg = {signs: proPickRows[index]};
                return myLeg;
            });
            setFormData(formData);
            setProPickRows(undefined);
            setValid(validateCoupon(formData));

            // console.debug('sending scroll to top (propick)');
            // operatorAdviceScrollToTop(500);
        }

        if (formData !== undefined && round !== undefined && coupon !== undefined) {

            formData.bets = coupon.bets;
            setFormData(formData);
        }

        if (formData !== undefined && round !== undefined && coupon === undefined
            && formData.bets[0].legs.map(leg => leg.signs.length).reduce((total, length) => total + length) === 0
            && selectedIndex === 0
        ) {
            //First render
            // console.debug('sending scroll to top (first render)');
            // operatorAdviceScrollToTop(500);
        }

    }, [formData, round, proPickRows, setProPickRows, coupon, selectedIndex])


    let player = playerResp[0];
    let isRealMode = player && player._embedded && player._embedded.mode === 'real';

    const isMobileScreen = useMediaQuery('(max-width: 950px)');

    let isRightToLeft = IsRightToLeftTheme(theme);

    function newBet(fixtures: Fixture[]): Bet {

        let system: System = {
            type: "MATHEMATICAL",
        };

        const legs = fixtures.map((_fixture, _index) => {
            let myLeg: Leg = {signs: []};
            return myLeg;
        });
        return (
            {
                system: system,
                legs: legs
            }
        );
    }

    const handleFixtureClick = (index: number) => {
        onChangeFixture(index);
        setSelectedIndex(index);
    }

    function updateProPickRows(rows: Array<Array<string>>): void {

        let bet = newBet(round.fixtures);
        bet.legs = round.fixtures.map((_fixture, index) => {
            let myLeg: Leg = {signs: rows[index]};
            return myLeg;
        });

        let coupon: Coupon | undefined = {
            bets: [bet],
            stakePerRow: round.operatorStakePerRow,
            roundId: round.id
        }

        setFormData(coupon);
        setValid(validateCoupon(coupon));
    }


    if (round !== undefined && formData === undefined) {

        let coupon: Coupon | undefined = {
            bets: [newBet(round.fixtures)],
            stakePerRow: round.operatorStakePerRow,
            roundId: round.id
        }
        setFormData(coupon);
    }

    if (round === undefined || formData === undefined) {
        return <></>
    }

    const isAfterDeadline = new Date(round.openTo) < new Date(Date.now());

    let locale = t('locale');
    const nf = PoolXNumberFormat(locale, {
        style: 'currency',
        currency: round.operatorStakePerRow.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const handleChange = (index: number, pick: string) => {

        if (isAfterDeadline || coupon !== undefined) {
            return;
        }

        let newBets = formData!.bets;
        let existing = newBets[0].legs[index].signs;

        let indexToRemove = existing.indexOf(pick);

        if (indexToRemove > -1) {
            existing.splice(indexToRemove, 1);
        } else {
            existing.push(pick);
        }

        newBets[0].legs[index].signs = existing;

        let newFormData: Coupon = {
            ...formData!,
            ...{bets: newBets}
        };

        setFormData(newFormData);
        setValid(validateCoupon(newFormData));
    };

    const handleRSystemChange = (system: System) => {

        let newBet = formData!.bets[0];
        newBet.system = system;

        let newFormData: Coupon = {
            ...formData!,
            ...{bets: [newBet]}
        };

        setFormData(newFormData);
        setValid(validateCoupon(newFormData));
    };

    function validateCoupon(coupon: Coupon): boolean {

        if (!coupon) {
            return false;
        }

        const {bets} = coupon;
        const {legs, system} = bets[0];

        let halfCover = 0;
        let fullCover = 0;

        let valid = true;
        for (let i = 0; i < legs.length; i++) {
            const signs = legs[i].signs;

            if (signs.length === 0) {
                valid = false;
                break;
            }

            if (signs.length === 2) {
                halfCover++;
            }

            if (signs.length === 3) {
                fullCover++;
            }
        }

        if (system.type === 'REDUCED' && system.reducedSystemId) {
            let parts = system.reducedSystemId.split('_');
            if (parts[1] !== fullCover.toString()) {
                valid = false;
            }
            if (parts[2] !== halfCover.toString()) {
                valid = false;
            }
        }

        return valid;
    }

    const handleExpand = (index: number) => {

        let existing = expandedFixtures;
        let indexToRemove = existing.indexOf(index);
        if (indexToRemove > -1) {
            existing.splice(indexToRemove, 1);
        } else {
            existing.push(index);
        }
        setExpandedFixtures(existing);
        setCounter((c) => c + 1)
    };

    const resetForm = () => {
        let bet: Coupon | undefined = {
            bets: [newBet(round.fixtures)],
            stakePerRow: round.operatorStakePerRow,
            roundId: round.id
        }
        setFormData(bet)
        setValid(validateCoupon(bet));
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(formData!);
        resetForm();
    }

    const handleError = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    function isSelected(index: number, sign: string): boolean {
        if (formData!.bets.length > 1)
            return false;
        if (formData!.bets[0].system.type === "REDUCED" && couponFixtureSummaries)
            return false;
        return formData!.bets[0].legs[index].signs.indexOf(sign) > -1
    }

    function getSignStyle(round: DetailedRound, index: number, sign: string): SxProps<Theme> {

        if (couponFixtureSummaries && couponFixtureSummaries.length > 0 &&
            (formData!.bets.length > 1 || formData!.bets[0].system.type === "REDUCED")) {

            let summary = 0;

            switch (sign) {
                case "ONE":
                    summary = couponFixtureSummaries[index].signs.ONE.rowsFraction;
                    break;
                case "X":
                    summary = couponFixtureSummaries[index].signs.X.rowsFraction;
                    break;
                case "TWO":
                    summary = couponFixtureSummaries[index].signs.TWO.rowsFraction;
                    break;
            }

            let partial: SxProps<Theme> = {
                backgroundImage: 'linear-gradient(to top, var(--sign-background) 0 ' + summary * 100 + '%, var(--sign-background-not-selected) ' + summary * 100 + '% 100%)',
                color: summary > 0.5 ? 'var(--sign-color-selected)' : 'var(--sign-color)'
            }

            if (isAfterDeadline && round.preliminaryCorrectRow && round.preliminaryCorrectRow[index] === sign)
                return {...button, ...partial, ...correct} as SxProps<Theme>

            return {...button, ...partial} as SxProps<Theme>
        }


        if (isAfterDeadline && round.preliminaryCorrectRow && round.preliminaryCorrectRow[index] === sign)
            return {...button, ...correct} as SxProps<Theme>

        return button;
    }

    let rowPrice = Number(round.operatorStakePerRow.amount);
    if (round.operatorFeePerRow !== undefined && round.operatorFeePerRow?.amount > 0) {
        rowPrice = rowPrice + Number(round.operatorFeePerRow.amount);
    }

    let clearBetsColor = "var(--background_2)";
    let placeBetColor = "var(--button-color)";

    let backgroundImage = "";

    if (theme === themeC.Takbet || theme === themeC.Takbetme) {
        backgroundImage = 'linear-gradient(to bottom, #a3752b 0%,#f1e495 50%,#d7a94f 100%)';
        placeBetColor = 'linear-gradient(to bottom, #a3752b 0%,#f1e495 50%,#d7a94f 100%)';
    }

    if (theme === themeC.Marcbet || theme === themeC.Marcbetme) {
        backgroundImage = 'linear-gradient(#FFD000, #FFF194, #FFD000)';
    }

    let button: SxProps<Theme> = {
        padding: "0px 5px",
        textAlign: "center",
        height: "40px",
        fontFamily: "var(--font-body)",
        minWidth: "40px",
        maxWidth: "40px",
        marginRight: "10px",
        border: 'var(--sign-border-width) solid var(--off-black)',
        borderRadius: 'var(--sign-border-radius)',
        background: 'var(--sign-background-not-selected)',
        color: 'var(--sign-color)',
        "&:hover": {
            backgroundColor: 'var(--sign-hover-background)',
        },
        "&.Mui-selected": {
            color: 'var(--sign-color-selected)',
            backgroundColor: 'var(--sign-background)',
            backgroundImage: backgroundImage,
        },
        "&.Mui-selected:hover": {
            color: 'var(--sign-color-selected)',
            backgroundColor: 'var(--sign-hover-background)',
        }
    }

    let correct: SxProps<Theme> = {
        backgroundColor: 'var(--sign-correct-border)',
    }

    if (coupon !== undefined) {
        correct = {
            borderColor: 'var(--sign-correct-border)',
            borderWidth: "3px"
        }
    }

    const lastButton: SxProps<Theme> = {
        marginRight: "0px",
    }

    let statButton: SxProps<Theme> = {
        padding: "0px 5px",
        height: "20px",
        minWidth: "20px",
        maxWidth: "20px",
        marginRight: "10px",
        border: '0 solid',
        borderRadius: 'var(--sign-border-radius)',
        background: 'var(--background_2)',
        "&:hover": {
            color: 'var(--off-black)',
            backgroundColor: 'var(--background_1)',
        },
        "&.Mui-selected": {
            color: 'var(--off-black)',
            backgroundColor: 'var(--background_1)'
        }
    }

    let signText: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "18px",
        fontFamily: "var(--font-body)"
    }

    let teams: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "19px",
        fontWeight: "500",
        fontFamily: "var(--font-body)",
        textAlign: isRightToLeft ? "right" : ""
    }

    let time: SxProps<Theme> = {
        fontSize: "12px",
        lineHeight: "19px",
        fontWeight: "400",
        fontFamily: "var(--font-body)",
        marginTop: "2px"
    }

    let gameNr: SxProps<Theme> = {
        width: "20px",
        marginRight: "5px"
    }

    const titleOverview: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "19px",
        fontWeight: "bold",
        fontFamily: "var(--font-body)",
        marginTop: "5px",
        marginBottom: "5px",
        color: "var(--text-black-dark-background)"
    }

    const textDetail: SxProps<Theme> = {
        fontSize: "11px",
        lineHeight: "19px",
        fontFamily: "var(--font-body)",
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "3px",
        color: "var(--text-black-dark-background)"
    }

    const mobileRowDistStyle: SxProps<Theme> = {
        paddingTop: "20px", 
        paddingBottom: "20px", 
        marginLeft: isRightToLeft? "0px" : "20px"
    }

    let goldBox: SxProps<Theme> = {};

    if (theme === themeC.Gold) {
        goldBox = {
            padding: "5px 10px", border: "1px solid #CACACA",
            borderRadius: "10px", margin: "5px"
        }

        teams = {...teams, ...{fontWeight: "600"}};
    }

    if ((theme === themeC.Kung)) {
        signText = {
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "23.44px",
            fontFamily: "var(--font-body)"
        }
        button = {...button, ...{border: '1px solid #249CEC'}};
        clearBetsColor = "var(--button-color)";
        placeBetColor = "linear-gradient(180deg, #66C72A 0%, #238500 100%)";
        statButton = {
            ...statButton, ...{
                color: '#C3E7FF',
                "&:hover": {
                    color: '#249CEC',
                    backgroundColor: 'var(--background_1)',
                },
                "&.Mui-selected": {
                    color: '#249CEC',
                    backgroundColor: 'var(--background_1)'
                }
            }
        };
    }

    if ((theme === themeC.Dbet)) {
        signText = {
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "23.44px",
            fontFamily: "var(--font-body)"
        }

        teams = {
            fontSize: "14px",
            lineHeight: "16.41px",
            fontWeight: "700",
            fontFamily: "var(--font-body)",
            textAlign: isRightToLeft ? "right" : ""
        }
        time = {
            fontSize: "10px",
            lineHeight: "11.72px",
            fontWeight: "400",
            fontFamily: "var(--font-body)",
            marginTop: "5px"
        }

        gameNr.color="#5E5E5E";

        
        button = {...button, ...{border: '1px solid #249CEC'}};
        clearBetsColor = "var(--button-color)";
        placeBetColor = "linear-gradient(180deg, #4075FF 0%, #4075FF 100%)";
        statButton = {
            ...statButton, ...{
                color: '#C3E7FF',
                "&:hover": {
                    color: '#249CEC',
                    backgroundColor: 'var(--background_1)',
                },
                "&.Mui-selected": {
                    color: '#249CEC',
                    backgroundColor: 'var(--background_1)'
                }
            }
        };
    }

    const displayDrawText = (fixture: Fixture) => {
        const draw = fixture.draw;
        return draw && (draw.status === 'PUBLISHED' || draw.status === 'FINISHED');
    }

    const handlePayClick = () => {
        setShowConfirm(true);
        operatorAdviceScrollToTop(500);
    }

    const {bets} = formData || {};
    const [bet] = bets || [];
    const {legs} = bet || {};
    const {wholePuts, halfPuts} = getWholeAndHalfPutsFromArray(legs);

    let totalStake = 0;
    if (formData.stakePerRow !== undefined) {
        totalStake = couponTotal(round, formData, stakeMultiplier);
    }

    let operatorFeePerRow: PoolXMoney = {amount: 0, currency: round.operatorStakePerRow.currency};
    if (round.operatorFeePerRow !== undefined && round.operatorFeePerRow !== null) {
        operatorFeePerRow = round.operatorFeePerRow;
    }

    if (showRoundInfo && isMobileScreen) {
        return (
            <RoundInformation round={round} setShowRoundInfo={setShowRoundInfo}/>
        )
    }

    const onRSystemDialogChange = (id: string) => {
        handleRSystemChange({type: 'REDUCED', reducedSystemId: id});
        setShowRSystem(false);
    }

    let reducedWhole = "";
    let reducedHalf = "";
    let systemName = "";

    if (formData.bets[0].system.type === "REDUCED") {
        const parts = formData.bets[0].system.reducedSystemId?.split("_");
        if (parts !== undefined && parts?.length > 2) {
            reducedWhole = '(' + parts[1] + ')';
            reducedHalf = '(' + parts[2] + ')';
            systemName = t('reduced_system') + ' ' + bet.system.reducedSystemId;
        }
    }

    function clearTextColor(): string {

        if (theme === themeC.Marcbet || theme === themeC.Marcbetme || theme === themeC.Dbet) {
            return "var(--button-text-color)";
        }
        return "var(--text-black)";
    }

    return (
        <div>
            {showProPick && (isMobileScreen ?

                    <ProPick setProPickRows={updateProPickRows} onShowProPick={(x: boolean) => setShowProPick(x)}
                             legs={bet.legs} fixtures={round.fixtures}
                             operatorStakePerRow={round.operatorStakePerRow ?? {amount: 0}}
                             operatorFeePerRow={operatorFeePerRow} proPickName={t("pro_pick")}/>
                    :
                    <ShadowDiv>
                        <ProPick setProPickRows={updateProPickRows} onShowProPick={(x: boolean) => setShowProPick(x)}
                                 legs={bet.legs} fixtures={round.fixtures}
                                 operatorStakePerRow={round.operatorStakePerRow ?? {amount: 0}}
                                 operatorFeePerRow={operatorFeePerRow} proPickName={t("pro_pick")}/>
                    </ShadowDiv>
            )
            }

            {showRSystem && (
                <ShadowDiv>
                    <div className="shadow-content start dyn-height top-screen">
                        <div className="close-flex">
                            <span/>
                            <span className="bold">{t('reduce')}</span>
                            <CloseIcon onClick={() => setShowRSystem(false)}/>
                        </div>
                        {round.reducedSystems.length > 0 ?
                            <ReducedSystemSelector reducedSystems={round.reducedSystems}
                                                   onReset={() => handleRSystemChange({type: 'MATHEMATICAL'})}
                                                   onChange={(id: string) => onRSystemDialogChange(id)}
                                                   numGames={round.fixtures.length}/>
                            :
                            <span>{t('no_reduced_systems_available')}</span>}
                    </div>
                </ShadowDiv>
            )}

            <form onSubmit={handleSubmit} onError={handleError}>
                {showConfirm && (
                    <ConfirmPayment
                        amount={couponTotal(round, formData, stakeMultiplier)}
                        currency={round.operatorStakePerRow.currency}
                        toggle={setShowConfirm}
                        submitting={submitting}
                        submitFailed={submitFailed}
                        resetValues={resetForm}
                        submitError={submitError}
                    />
                )}
                <Grid container sx={{justifyContent: "center", marginBottom: isAfterDeadline ? "0px" : "20px"}}>
                    <Grid container direction="column"
                          sx={{minWidth: "340px", maxWidth: "500px", background: 'var(--background_2)', margin: "0px"}}>
                        {round.fixtures.map((fixture, index) => {

                            let selected = index === selectedIndex && !isMobileScreen;

                            const basic: SxProps<Theme> = {
                                backgroundColor: selected ? "var(--fixture-selected)" : "",
                                color: selected ? "var(--text-black-dark-background)" : "var(--text-black)",
                                padding: "10px",
                                borderBottom: "1px solid var(--off-black)",
                                borderTop: index === 0 ? "1px solid var(--off-black)" : ""
                            };

                            if ((theme === themeC.Kung)) {
                                goldBox = {
                                    borderLeft: selected ? "4px solid #249CEC" : "4px solid #081431",
                                }
                            }

                            if ((theme === themeC.Dbet)) {
                                goldBox = {
                                    borderLeft: selected ? "8px solid #3058B8" : "8px solid #EEEEEE",
                                }                                
                            }

                            let styleRow: SxProps<Theme> = {...basic, ...goldBox};

                            return (
                                <Grid key={"row_header" + index} sx={styleRow}
                                      onClick={() => handleFixtureClick(index)}>
                                    <Grid container key={"row_" + index}
                                          sx={{flexDirection: "var(--flex-direction)", flexWrap: "nowrap"}}
                                          alignItems="stretch">
                                        <Grid item>
                                            <Typography
                                                sx={{...teams, ...gameNr} as SxProps<Theme>}>{index + 1}</Typography>
                                        </Grid>
                                        <Grid item sx={{flexGrow: 1, paddingRight: "5px"}}>
                                            <Grid container sx={{flexDirection: "var(--flex-direction)"}}>
                                                <Typography sx={teams}>{fixture.homeTeam.name}</Typography>
                                            </Grid>
                                            <Grid container sx={{flexDirection: "var(--flex-direction)"}}>
                                                <Typography sx={teams}>{fixture.awayTeam.name}</Typography>
                                            </Grid>
                                            <Grid container sx={{flexDirection: "var(--flex-direction)"}}>
                                                {displayDrawText(fixture) ? <Typography
                                                        sx={{...time, ...{fontWeight: "bold"}}}>{getDrawText(fixture.draw, t)}</Typography> :
                                                    <Typography sx={time}>{fixtureTime(fixture, t)}</Typography>}
                                            </Grid>

                                        </Grid>
                                        {isAfterDeadline && new Date(fixture.startingAt) < new Date(Date.now()) && !displayDrawText(fixture) &&
                                            <Grid item sx={{paddingRight: "40px"}}>
                                                <Grid container sx={{justifyContent: "center"}}>
                                                    <Typography sx={teams}>{fixture.homeTeam.score}</Typography>
                                                </Grid>
                                                <Grid container sx={{justifyContent: "center"}}>
                                                    <Typography sx={teams}>{fixture.awayTeam.score}</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        {isMobileScreen &&
                                            <Grid item sx={{minHeight: '100%'}}>
                                                <Grid container alignItems="center" direction="row"
                                                      sx={{minHeight: '100%'}}>
                                                    <ToggleButton name="stat" value="stat"
                                                                  selected={expandedFixtures.indexOf(index) > -1}
                                                                  onChange={() => handleExpand(index)} sx={statButton}>
                                                        <EqualizerIcon/>
                                                    </ToggleButton>
                                                </Grid>
                                            </Grid>
                                        }

                                        <Grid item sx={{minHeight: '100%'}}>
                                            <Grid container alignItems="center" sx={{
                                                minHeight: '100%',
                                                flexDirection: "var(--flex-direction)",
                                                flexWrap: "nowrap"
                                            }}>
                                                <ToggleButton name="1" onChange={() => handleChange(index, "ONE")}
                                                              selected={isSelected(index, "ONE")}
                                                              value="1" sx={getSignStyle(round, index, "ONE")}>
                                                    <Typography sx={signText}>1</Typography>
                                                </ToggleButton>
                                                <ToggleButton name="X" onChange={() => handleChange(index, "X")}
                                                              selected={isSelected(index, "X")}
                                                              value="X" sx={getSignStyle(round, index, "X")}>
                                                    <Typography sx={signText}>X</Typography>
                                                </ToggleButton>
                                                <ToggleButton name="2" onChange={() => handleChange(index, "TWO")}
                                                              selected={isSelected(index, "TWO")}
                                                              value="2" sx={
                                                    isRightToLeft ? getSignStyle(round, index, "TWO") : {...getSignStyle(round, index, "TWO"), ...lastButton} as SxProps<Theme>}>
                                                    <Typography sx={signText}>2</Typography>
                                                </ToggleButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        expandedFixtures.indexOf(index) > -1 &&
                                        <Grid container direction="column">
                                            <Grid item sx={mobileRowDistStyle}>
                                                <Grid container direction="row" sx={{justifyContent: "space-between", flexDirection: "var(--flex-direction)"}}>
                                                    <Typography sx={time}>{t('row_distribution')}</Typography>
                                                    <Grid item>
                                                    {isRightToLeft &&
                                                        <Grid container direction="row" sx={{ flexDirection: "var(--flex-direction)"}}>                                                           
                                                            <Grid container sx={{justifyContent: "center", marginLeft: "10px"}} width={"40px"}>
                                                                <Typography sx={time}>1: {formatFraction(fixture.signs.ONE?.rowsFraction ?? 0)}</Typography>
                                                            </Grid>
                                                            <Grid container
                                                                sx={{justifyContent: "center", marginLeft: "10px"}}
                                                                width={"40px"}>
                                                                <Typography
                                                                    sx={time}>X: {formatFraction(fixture.signs.X?.rowsFraction ?? 0)}</Typography>
                                                            </Grid>
                                                            <Grid container sx={{justifyContent: "center"}}
                                                                width={"40px"}>
                                                                <Typography
                                                                    sx={time}>2: {formatFraction(fixture.signs.TWO?.rowsFraction ?? 0)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {!isRightToLeft &&
                                                        <Grid container direction="row" sx={{ flexDirection: "var(--flex-direction)"}}>
                                                            <Grid container sx={{justifyContent: "center", marginRight: "10px"}} width={"40px"}>
                                                                <Typography sx={time}>{formatFraction(fixture.signs.ONE?.rowsFraction ?? 0)}</Typography>
                                                            </Grid>
                                                            <Grid container
                                                                sx={{justifyContent: "center", marginRight: "10px"}}
                                                                width={"40px"}>
                                                                <Typography
                                                                    sx={time}>{formatFraction(fixture.signs.X?.rowsFraction ?? 0)}</Typography>
                                                            </Grid>
                                                            <Grid container sx={{justifyContent: "center"}}
                                                                width={"40px"}>
                                                                <Typography
                                                                    sx={time}>{formatFraction(fixture.signs.TWO?.rowsFraction ?? 0)}</Typography>
                                                            </Grid>                                                         
                                                        </Grid>
                                                    }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item
                                                  sx={{paddingTop: "20px", paddingBottom: "20px", marginLeft: "20px"}}>
                                                <SlimFixtureDetail theme={theme} fixture={fixture}/>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            );
                        })
                        }

                        {!isAfterDeadline && coupon === undefined &&
                            <Grid container justifyContent="center" direction="column" sx={{
                                padding: "20px",
                                flexDirection: "var(--flex-direction)",
                                backgroundColor: "var(--background_4)"
                            }}>
                                <Typography sx={titleOverview}>{t('overview')}</Typography>
                                <Grid container direction="column">
                                    <Grid container direction="row" justifyContent="space-between">
                                        <Grid item>
                                            <Grid container
                                                  sx={{alignItems: "center", flexDirection: "var(--flex-direction)"}}>
                                                {CircleIcon}
                                                <Typography
                                                    sx={textDetail}>{t('full_cover')}: {wholePuts} {reducedWhole} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container
                                                  sx={{alignItems: "center", flexDirection: "var(--flex-direction)"}}>
                                                {HalfCircleIcon}
                                                <Typography
                                                    sx={textDetail}>{t('half_cover')}: {halfPuts} {reducedHalf} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container
                                                  sx={{alignItems: "center", flexDirection: "var(--flex-direction)"}}>
                                                {CashIcon}
                                                {isRightToLeft ?
                                                    <Typography
                                                        sx={textDetail}>{nf.format(rowPrice)} : {t('row_price')}</Typography> :
                                                    <Typography
                                                        sx={textDetail}>{t('row_price')} : {nf.format(rowPrice)}</Typography>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {systemName !== null && systemName !== '' &&
                                        <Grid container direction="row" justifyContent="center">
                                            <Grid item>
                                                <Typography sx={textDetail}>{systemName}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {syndicate === undefined &&
                                        <StakeMultiplier t={t} stakeMultiplier={stakeMultiplier}
                                                         setStakeMultiplier={setStakeMultiplier}
                                                         isRightToLeft={isRightToLeft}></StakeMultiplier>
                                    }
                                    {

                                        syndicate === undefined &&
                                        <Grid item sx={{topMargin: "20px"}}>
                                            <Grid container direction={isRightToLeft ? "row-reverse" : "row"}
                                                  justifyContent={"center"} sx={{marginTop: "20px"}}>
                                                <Button
                                                    onClick={() => resetForm()}
                                                    type="button"
                                                    padding="12px 12px"
                                                    margin="10px"
                                                    color={clearBetsColor}
                                                    textColor={clearTextColor}
                                                >
                                                    {t('clear_bets')}
                                                </Button>
                                                <Button
                                                    disabled={!isRealMode || !valid}
                                                    onClick={() => handlePayClick()}
                                                    type="button"
                                                    padding="12px 12px"
                                                    margin="10px"                                                    
                                                    backgroundImage={placeBetColor}
                                                >
                                                    {t('pay')} {nf.format(couponTotal(round, formData, stakeMultiplier))}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }

                                    {syndicate !== undefined &&
                                        <Grid container direction="column" sx={{padding: "20px"}}>
                                            <Grid container
                                                  sx={{alignItems: "center", flexDirection: "var(--flex-direction)"}}>
                                                <Typography sx={titleOverview}>Total
                                                    stake: {nf.format(totalStake)}</Typography>
                                            </Grid>
                                            <Grid container
                                                  sx={{alignItems: "center", flexDirection: "var(--flex-direction)"}}>
                                                <Typography sx={titleOverview}>Stake per
                                                    share: {nf.format(Math.round(100 * totalStake / syndicate.targetNShares) / 100)}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                        {syndicate !== undefined &&
                            <Grid container
                                  sx={{padding: "30px", background: "var(--background_1)", justifyContent: "center"}}>

                                <Button
                                    disabled={totalStake / syndicate.targetNShares < 1}>{t('submit_syndicate')}</Button>

                            </Grid>
                        }
                    </Grid>
                </Grid>

            </form>

        </div>
    );

}

export default SlimCoupon;