import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { KungBetsIcon, KungHomeIcon, KungInfoIcon, KungRoundIcon, KungSyndicateIcon } from '../images/kungicons';
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { themeC } from '../theme/themehelper';
import { CashIconWhite, HouseIconWhite, TicketIconWhite } from '../reusable-components';

function MobileNavBar({ theme, realMode, agentSyndicateBetting, reportSelection }: {
    theme: string, realMode: boolean, agentSyndicateBetting: boolean,
    reportSelection: (type: string, id: string) => void
}) {
    const { t } = useTranslation();

    let mobileRoundIcon = KungRoundIcon();
    let mobileBetsIcon = TicketIconWhite(18, 18);
    let mobileSyndicateIcon = CashIconWhite(18, 18);
    let mobileHomeIcon = HouseIconWhite(20, 20);
    let mobileInfoIcon = KungInfoIcon();

    if (theme === themeC.Kung) {
        mobileRoundIcon = KungRoundIcon();
        mobileBetsIcon = KungBetsIcon;
        mobileSyndicateIcon = KungSyndicateIcon;
        mobileHomeIcon = KungHomeIcon;
        mobileInfoIcon = KungInfoIcon();
    }

    if (theme === themeC.Orange) {
        mobileRoundIcon = KungRoundIcon("var(--text-black)");
        mobileBetsIcon = TicketIconWhite(18, 18, "var(--text-black)");
        mobileSyndicateIcon = CashIconWhite(18, 18, "var(--text-black)");
        mobileHomeIcon = HouseIconWhite(20, 20, "var(--text-black)");
        mobileInfoIcon = KungInfoIcon("var(--text-black)");
    }

    const gridStyle: SxProps<Theme> = {
        paddingTop: "10px",
        color: "var(--title-bar-color)",
        height: "50px",
        '& > a': { textDecoration: "none" },
        background: 'var(--title-bar-background)',
        flexDirection: "var(--flex-direction)"        
    }

    const textStyle: SxProps<Theme> = {
        fontSize: "12px",
        margin: "5px",
        color: "var(--title-bar-color)",
        fontFamily: 'var(--font-title-3)',
    }

    const itemStyle: SxProps<Theme> = {
        flexDirection: "var(--flex-direction)"        
    }

    return (
        <Grid sx={gridStyle} container justifyContent="space-evenly">
            <Link to="/clear">
                <Grid container sx={itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("home", "mobile_menu")}>
                    {mobileHomeIcon}
                </Grid>
            </Link>

            <Link to="/roundhistory">
                <Grid container sx={itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("rounds", "mobile_menu")}>
                    {mobileRoundIcon}
                    <Typography sx={textStyle}>{t('rounds')}</Typography>
                </Grid>
            </Link>

            {realMode &&
                <Link to="/mycoupons">
                    <Grid container sx={itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("coupons", "mobile_menu")}>
                        {mobileBetsIcon}
                        <Typography sx={textStyle}>{t('my_bets')}</Typography>
                    </Grid>
                </Link>
            }

            {agentSyndicateBetting &&
                <Link to="/playagent">
                    <Grid container sx={itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("syndicates", "mobile_menu")}>
                        {mobileSyndicateIcon}
                        <Typography sx={textStyle}>{t('bet_with_syndicates')}</Typography>
                    </Grid>
                </Link>
            }

            <Link to="/help">
                <Grid container sx={itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("help", "mobile_menu")}>
                    {mobileInfoIcon}
                    <Typography sx={textStyle}>{t('help')}</Typography>
                </Grid>
            </Link>
        </Grid>
    );

}

export default MobileNavBar;